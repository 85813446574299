import React from "react"
import { css } from "styled-components"

function drawCircles(itemCount, circleSize, itemSize) {
  const angle = 360 / itemCount
  let rotation = -90

  function cal() {
    let styles = ""

    for (let i = 1; i <= itemCount; i++) {
      styles += `
        &:nth-of-type(${i}) {
          transform: rotate(${rotation * 1}deg) translate(${circleSize / 2}rem)
            rotate(${rotation * -1}deg);
        }
      `

      rotation = rotation + angle
    }

    return css`
      ${styles}
    `
  }

  return css`
    position: relative;
    width: ${circleSize}rem;
    height: ${circleSize}rem;
    border-radius: 50%;

    > * {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: ${itemSize}rem;
      height: ${itemSize}rem;
      margin: -${itemSize / 2}rem;

      ${cal()}
    }
  `
}

export default function Circle({
  children,
  items = [],
  circleSize = 40,
  itemSize = 6,
}) {
  return (
    <div
      css={`
        position: relative;
      `}
    >
      <div
        css={`
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `}
      >
        {children}
      </div>
      <ul
        css={`
          ${drawCircles(items.length, circleSize, itemSize)}

          img {
            display: block;
            max-width: 100%;
            border-radius: 50%;
            filter: grayscale(100%);
            border: solid 5px tomato;
            transition: 0.15s;

            &:hover,
            &:active {
              filter: grayscale(0);
            }
          }
        `}
      >
        {items.map(item => (
          <li key={item.id}>{item.render()}</li>
        ))}
      </ul>
    </div>
  )
}
