import React from "react"
import Layout from "../components/common/Layout"
import SEO from "../components/common/SEO"
import Divider from "../components/common/Divider"
import { DEVICES } from "../config"
import useWindowSize from "../hooks/useWindowSize"
import WhatWeMeasure from "../components/the-science/WhatWeMeasure"
import Hero from "../components/the-science/Hero"
import BreathAnalysis from "../components/the-science/BreathAnalysis"
import Wearable from "../components/the-science/Wearable"

export default function TheSciencePage() {
  const { width } = useWindowSize()

  return (
    <Layout>
      <SEO title="The Science" />
      <Divider.Light />
      {width < DEVICES.desktop ? (
        <>
          <Hero.Mobile />
          <Divider.Light />
          <WhatWeMeasure.Mobile />
          <Divider.Light />
          <BreathAnalysis.Mobile />
          <Divider.Light />
          <Wearable.Mobile />
        </>
      ) : (
        <>
          <Hero />
          <Divider.Light />
          <WhatWeMeasure />
          <Divider.Light />
          <BreathAnalysis />
          <Divider.Light />
          <Wearable />
        </>
      )}
    </Layout>
  )
}
