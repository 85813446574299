import React from "react"
import Container from "../why-get-tested/Container"
import { StaticImage } from "gatsby-plugin-image"
import Button from "../common/Button"
import { Link } from "gatsby"
import { H1, P } from "../common/Typography"
import { MEDIA_QUERIES } from "../../config"

function Veil() {
  return (
    <div
      css={`
        position: absolute;
        left: 0;
        top: 0;
        background-color: rgba(25, 25, 25, 0.64);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--main);
        text-align: center;
      `}
    >
      <div
        css={`
          max-width: 40rem;

          ${MEDIA_QUERIES.belowDesktop} {
            max-width: 32rem;
          }
        `}
      >
        <H1 mb={32} weight="bold">
          The way you breathe is the way you live.
        </H1>
        <Button.Plain as={Link} to="/payment">
          Get your PNOĒ test now
        </Button.Plain>
      </div>
    </div>
  )
}

const Title = props => (
  <P opacity={1} weight="bold" {...props}>
    Breath Analysis is the most accurate way of measuring your metabolism and
    finding the plan your physiology needs. We made it accessible to everyone.
  </P>
)

const Description = props => (
  <P
    mt={16}
    css={`
      font-size: 14px;
      line-height: 1.43;
    `}
    {...props}
  >
    Through 10,000+ peer review studies conducted over 100+ years, Breath
    Analysis has been established as the gold standard in metabolic testing. Our
    state-of-the-art device measures your metabolism with clinical precision.
    Then, our AI, built upon 50,000+ breath profiles and 1000+ peer-reviewed
    studies, analyzes your metabolism to generate the most personalized
    training, nutrition, and recovery insights science can offer.
  </P>
)

const Texts = () => (
  <Container
    bleed="both"
    py="6.4rem"
    css={`
      flex-direction: column;
      text-align: center;
    `}
  >
    <div
      css={`
        max-width: 70rem;
      `}
    >
      <Title />
      <Description />
    </div>
  </Container>
)
export default function Hero() {
  return (
    <>
      <Container>
        <StaticImage
          placeholder="blurred"
          src="../../assets/images/the-science/hero.jpg"
        />
        <Veil />
      </Container>
      <Texts />
    </>
  )
}

Hero.Mobile = () => (
  <>
    <Container
      css={`
        padding-top: 0;
        padding-bottom: 0;

        .gatsby-image-wrapper {
          margin: 0;
        }
      `}
    >
      <StaticImage
        placeholder="blurred"
        src="../../assets/images/the-science/hero-mobile.jpg"
      />
      <Veil />
    </Container>
    <Texts />
  </>
)
