import React from "react"
import styled from "styled-components"
import { MEDIA_QUERIES } from "../../config"
import { Text } from "../common/Typography"

const IconContainer = styled.button`
  --z: 5px;
  --size: ${props => (props.size ? props.size : "6rem")};

  box-shadow: calc(var(--z) * -1) calc(var(--z) * -1) calc(var(--z) * 2) 0
      #fffefe,
    calc(var(--z) * 1) calc(var(--z) * 1) calc(var(--z) * 2) 0
      rgba(25, 25, 25, 0.2);
  border-radius: 100%;
  width: var(--size);
  height: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: box-shadow, border;
  cursor: pointer;
  border: 2px solid
    ${props =>
      props.isActive
        ? "#bf0049"
        : props.type === "alt"
        ? "#1979ff"
        : "transparent"};

  :hover {
    --z: 2px;
  }

  svg {
    width: 50%;
    transition-timing-function: ease-in-out;
    transition-duration: 200ms;
    transition-property: fill;
    ${props => (props.type === "alt" && props.isActive ? `fill: #bf0049;` : "")}
  }

  ${MEDIA_QUERIES.belowDesktop} {
    width: 4rem;
    height: 4rem;
  }
`

export default function CircleItem({
  item,
  size,
  isActive,
  onClick,
  type = "default",
}) {
  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
      `}
    >
      <IconContainer
        size={size}
        isActive={isActive}
        onClick={onClick}
        type={type}
      >
        {item.icon}
      </IconContainer>
      <Text as="label" size="sm" opacity={0.55} mt={8} weight={500}>
        {item.name}
      </Text>
    </div>
  )
}
