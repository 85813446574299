import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import { WHAT_WE_MEASURE_ITEMS } from "../../config"
import Button from "../common/Button"
import RawCircle from "../common/Circle"
import Container from "../why-get-tested/Container"
import CircleDescription from "./CircleDescription"
import CircleItem from "./CircleItem"

const DATA = {
  title: "What we measure",
  descriptions: [
    "Breath contains ten critical biomarkers related to cells, heart, lungs, blood circulation, and the brain. They are the basis for evaluating overall health and personalizing training and nutrition regimens, whether for weight loss or breaking a world record.",
  ],
  button: {
    name: "Dive into the science",
    to: "/blog/breath-analysis-the-most-complete-picture-of-your-body",
  },
}

const Title = props => (
  <Container.Title width="40rem" {...props}>
    {DATA.title}
  </Container.Title>
)
const Description = props => (
  <Container.Description width="38rem" {...props}>
    {DATA.descriptions[0]}
  </Container.Description>
)

const CTA = props => (
  <Button as={Link} to={DATA.button.to} {...props}>
    {DATA.button.name}
  </Button>
)

function Circle({ size = "default", ...props }) {
  const [activeItem, setActiveItem] = useState(WHAT_WE_MEASURE_ITEMS[0])

  return (
    <div
      css={`
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: auto;
        max-width: 50rem;

        & > :first-child {
          margin: 6.4rem 0;
        }
      `}
    >
      <RawCircle
        items={WHAT_WE_MEASURE_ITEMS.map(item => ({
          id: item.id,
          render: () => (
            <CircleItem
              size={size === "mobile" && "4rem"}
              isActive={activeItem.id === item.id}
              onClick={() => setActiveItem(item)}
              item={item}
            />
          ),
        }))}
        circleSize={size === "mobile" ? 30 : 40}
        itemSize={size === "mobile" ? 5 : 10}
        {...props}
      >
        <StaticImage
          src="../../assets/images/the-science/what-we-measure.png"
          alt="pnoe ai"
          width={250}
          placeholder="tracedSVG"
        />
      </RawCircle>
      <CircleDescription
        icon={activeItem.icon}
        name={activeItem.name}
        description={activeItem.description}
      />
    </div>
  )
}

export default function Hero() {
  return (
    <Container bleed="both" py="6rem">
      <div>
        <Title />
        <Description />
        <CTA />
      </div>
      <Circle />
    </Container>
  )
}

Hero.Mobile = () => (
  <Container bleed="both">
    <Title />
    <Description />
    <Circle size="mobile" />
    <CTA mt={24} />
  </Container>
)
