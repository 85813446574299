import React from "react"
import styled from "styled-components"
import { P, Text } from "../common/Typography"

const Container = styled.div`
  width: 100%;
  min-height: 20rem;
  height: 100%;
  padding: 2.4rem;
  border-radius: 6px;
  border: solid 1px rgba(255, 255, 255, 0.8);
  background-color: rgba(247, 247, 247, 0.8);

  svg {
    width: 30px;
    height: 30px;
    fill: #bf0049;
    margin-right: 14px;
  }
`

export default function CircleDescription({ icon, name, description }) {
  return (
    <Container>
      <div
        css={`
          display: flex;
          align-items: center;
        `}
      >
        {icon}
        <Text
          as="h3"
          weight="bold"
          css={`
            font-size: 20px;
            line-height: 1.4;
            text-align: left;
          `}
        >
          {name}
        </Text>
      </div>
      <P
        mt={16}
        css={`
          font-size: 14px;
          line-height: 1.45;
          text-align: left;
        `}
      >
        {description}
      </P>
    </Container>
  )
}
