import React from "react"
import Container from "../why-get-tested/Container"
import { H1, P } from "../common/Typography"
import { StaticImage } from "gatsby-plugin-image"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Autoplay } from "swiper/core"
import styled from "styled-components"
import IconArrow from "../../assets/images/icon-arrow-down.inline.svg"
import Button from "../common/Button"
import { MEDIA_QUERIES } from "../../config"
import { Link } from "gatsby"

SwiperCore.use([Autoplay])

function WatchSlider() {
  return (
    <Swiper
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      loop
      css={`
        height: 150px;
        width: 150px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .swiper-slide {
          color: var(--main);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      `}
      slidesPerView={1}
      direction="vertical"
    >
      <SwiperSlide>HRV</SwiperSlide>
      <SwiperSlide>Heart Rate</SwiperSlide>
      <SwiperSlide>Kcals</SwiperSlide>
      <SwiperSlide>Sleep</SwiperSlide>
    </Swiper>
  )
}

const Section = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const SectionImage = styled.div`
  position: relative;
  min-width: 60rem;
  text-align: center;

  ${MEDIA_QUERIES.belowDesktop} {
    min-width: unset;
    width: 100%;
  }
`

const Number = styled.div`
  height: 40px;
  width: 40px;
  margin-bottom: 14px;
  border-radius: 8px;
  box-shadow: -5px -5px 10px 0 rgb(255, 254, 254),
    5px 5px 10px 0 rgba(25, 25, 25, 0.2);
  color: rgb(0, 149, 255);
  display: inline-flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;

  ${MEDIA_QUERIES.belowDesktop} {
    margin-top: 32px;
  }
`

const Divider = styled(IconArrow)`
  align-self: flex-start;
  width: 60rem;
  fill: #1979ff;
  margin: ${props => props.margin};

  ${MEDIA_QUERIES.belowDesktop} {
    width: 100%;
  }
`

const DATA = {
  title: "Supercharging your wearable",
  descriptions: [
    "Fitness trackers use an estimation of the human metabolic profile to provide insights such as calorie burn. However, their generic algorithms provide low accuracy and limited insights into human health.",
    "Our algorithm calibrates your clients' wearable by baselining its raw data such as heart rate, movement, and heart rate variability with their metabolic profile measured by the PNOĒ test. This personalizes their wearables algorithms to their metabolism and improves its accuracy in measuring calories by up to 2x while also making it able to track fat and carbohydrate burn during exercise.",
    "Our algorithm also benchmarks their wearable data against a pool of 50,000+ metabolic profiles to identify trends correlated with specific physiological trends such as improvement in heart fitness or metabolic flexibility. A single PNOĒ test makes your clients' wearable able to track their health and metabolism like never before.",
  ],
}

const OneImage = () => (
  <SectionImage>
    <StaticImage
      src="../../assets/images/the-science/supercharging-1.png"
      alt="apple watch"
      placeholder="blurred"
    />
    <WatchSlider />
  </SectionImage>
)

const TwoImage = () => (
  <SectionImage>
    <StaticImage
      src="../../assets/images/the-science/supercharging-2.png"
      placeholder="blurred"
      width={370}
    />
  </SectionImage>
)

const ThreeImage = () => (
  <SectionImage>
    <StaticImage
      src="../../assets/images/the-science/supercharging-3.png"
      placeholder="blurred"
    />
  </SectionImage>
)

const SectionText = styled.div`
  max-width: 42rem;

  ${MEDIA_QUERIES.belowDesktop} {
    max-width: 35rem;
  }
`

const CTA = () => (
  <Button
    mt={24}
    as={Link}
    to="/blog/breath-analysis-the-most-complete-picture-of-your-body"
  >
    Learn More
  </Button>
)

export default function Wearable() {
  return (
    <Container
      bleed="both"
      css={`
        flex-direction: column;
      `}
      py="10rem"
    >
      <Container.Title mb={72} align="center">
        {DATA.title}
      </Container.Title>
      <Section>
        <OneImage />
        <SectionText>
          <Number>1</Number>
          <P>{DATA.descriptions[0]}</P>
        </SectionText>
      </Section>
      <Divider margin="64px 0 32px 0" />
      <Section>
        <TwoImage />
        <SectionText>
          <Number>2</Number>
          <P>{DATA.descriptions[1]}</P>
        </SectionText>
      </Section>
      <Divider margin="32px 0 64px 0" />
      <Section>
        <ThreeImage />
        <SectionText>
          <Number>3</Number>
          <P>{DATA.descriptions[2]}</P>
          <CTA />
        </SectionText>
      </Section>
    </Container>
  )
}

Wearable.Mobile = () => (
  <Container
    css={`
      .gatsby-image-wrapper {
        margin: 0;
      }
    `}
  >
    <Container.Title width="25rem" mb={32}>
      {DATA.title}
    </Container.Title>
    <OneImage />
    <SectionText>
      <Number>1</Number>
      <P>{DATA.descriptions[0]}</P>
    </SectionText>
    <Divider margin="64px 0 32px 0" />
    <TwoImage />
    <SectionText>
      <Number>2</Number>
      <P>{DATA.descriptions[1]}</P>
    </SectionText>
    <Divider margin="64px 0 64px 0" />
    <ThreeImage />
    <SectionText>
      <Number>3</Number>
      <P mb={32}>{DATA.descriptions[2]}</P>
    </SectionText>
  </Container>
)
